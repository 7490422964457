import { render, staticRenderFns } from "./sourcePage.vue?vue&type=template&id=bcbe3368&scoped=true&"
import script from "./sourcePage.vue?vue&type=script&lang=js&"
export * from "./sourcePage.vue?vue&type=script&lang=js&"
import style0 from "./sourcePage.vue?vue&type=style&index=0&id=bcbe3368&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbe3368",
  null
  
)

export default component.exports