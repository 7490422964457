<template>
  <div class="wrap">
    <div class="bigBlock">
      <div class="iframeWrap" :class="dualScreenInfo == 1 ? 'w-1292' : ''">
        <div class="lTag" :class="{
          lTag_bg1: typeOne,
          lTag_bg2: typeTwo,
          lTag_bg4: typeFour,
        }" v-if="!IsPurchase && type != 3" @click="zhifu">
          {{ type == 1 ? "购买套餐" : "购买教材" }}
        </div>
        <!-- <div class="fullScreen iconfont icon-quanping1"></div> -->
        <div class="dualScreen iconfont icon-shuangping" @click="dualScreen"></div>
        <div class="leftArrow" @click="debounce(nextPage, 200)">
          <div class="iconfont icon-xiangyou"></div>
        </div>
        <div class="rightArrow" @click="debounce(prePage, 200)">
          <div class="iconfont icon-xiangzuo"></div>
        </div>
        <div :class="dualScreenInfo == 1 ? 'pdfBox' : ''">
          <pdf ref="pdf" @progress="progress" @num-pages="total = $event" @loaded="loadPdfHandler" :page="currentPage"
            class="pdf-item" :src="names ? names : ''"></pdf>
          <!--  -->
          <pdf v-show="dualScreenInfo == 1" ref="pdf" @progress="progress" @num-pages="total = $event"
            @loaded="loadPdfHandler" :page="currentPage + 1" class="pdf-item" :src="names ? names : ''"></pdf>
        </div>
        <div class="coverBox" v-if="!IsPurchase && coverInfo">
          <div class="tipsWrap">
            <div class="tipsBlock">
              <i class="iconfont icon-suotou"></i>购买解锁全部教材
            </div>
            <div v-if="type != 3" class="tipsBtn" :class="{
              tipsBtn1: typeOne,
              tipsBtn2: typeTwo,
              tipsBtn4: typeFour,
            }" @click="zhifu">
              立即购买
            </div>
          </div>
        </div>
        <!-- <div class="contentWrap" v-show="contentInfo == 1" @click="contentChange">
                    <div class="contentBox" v-html="ImgSizeLimit(bookDetails ? bookDetails.chapterText : '')"></div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/api/bus";
import Vue from "vue";
import pdf from "vue-pdf";
import { getToken, getDomain } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
export default {
  components: {
    pdf,
  },
  data() {
    return {
      names: null, // pdf地址
      IsPurchase: null, // 是否购买
      id: null, // 传入ID  套餐id、资料包id、
      title: null, // 传入资料包名称
      schoolId: null, // 传入大类ID
      coverInfo: false,
      loading: null,

      currentPage: 1, // 当前页
      loadedRatio: 1, // 当前页加载进度
      total: 8, // 总页数
      dualScreenInfo: 0, // 是否双排 1 是
      contentInfo: false, // 目录是否显示
      tryRead: null, // 限定试看页数
      type: null, // 1 套餐 2 资料  3 知识点试看
      timer: null,
      typeOne: false,
      typeTwo: false,
      typeFour: false,
    };
  },
  watch: {
    currentPage() {
      if (this.currentPage == this.tryRead || this.currentPage > this.tryRead) {
        this.coverInfo = true;
      } else {
        this.coverInfo = false;
      }
    },
  },
  async created() {
    this.id = await this.$route.query.id;
    this.title = this.$route.query.title;
    this.names = this.$route.query.names;
    this.tryRead = this.$route.query.seeNum;
    this.IsPurchase = eval(this.$route.query.IsPurchase);
    this.type = this.$route.query.type;
    // this.getBanner();
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟
     * entrantsType=3   =>  聚学U
     * entrantsType=4   =>  大客户
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeFour = false;
    } else if (res.entrantsType === 4) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeFour = true;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeFour = true;
    }
  },
  methods: {
    // 点击立即报名按钮 跳转支付页
    zhifu() {
      if (this.type == 1) {
        let token = getToken();
        if (token) {
          this.$router.push(`/orders/classOrder?classId=${this.id}`);
        } else {
          Vue.prototype.goLoginView(true);
        }
      } else if (this.type == 2) {
        let token = getToken();
        if (token) {
          orderClient
            .addOrders(
              this.id,
              localStorage.getItem("userId"),
              this.title,
              5,
              this.title
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          Vue.prototype.goLoginView(true);
          // this.$router.push({ name: "登录页", params: { hzc: 1 } });
        }
      }
    },
    // pdf加载时
    loadPdfHandler(e) {
      // 加载的时候先加载第一页
      this.currentPage = 1;
    },
    /* 点击双屏 */
    dualScreen() {
      this.dualScreenInfo =
        this.dualScreenInfo == 1
          ? (this.dualScreenInfo = 0)
          : (this.dualScreenInfo = 1);
    },
    /* 防抖 */
    debounce(fn, time) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(fn, time);
    },
    // 上一页
    prePage() {
      if (this.IsPurchase) {
        let page = this.currentPage;
        page = page > 1 ? page - 1 : this.total;
        this.currentPage = page;
      } else if (
        !this.IsPurchase &&
        this.currentPage < this.tryRead + 1 &&
        this.currentPage > 1
      ) {
        let page = this.currentPage;
        page = page > 1 ? page - 1 : this.total;
        this.currentPage = page;
      } else {
        this.currentPage = this.tryRead;
      }
    },
    // 下一页
    nextPage() {
      if (this.IsPurchase) {
        let page = this.currentPage;
        page = page < this.total ? page + 1 : 1;
        this.currentPage = page;
      } else if (!this.IsPurchase && this.currentPage < this.tryRead) {
        let page = this.currentPage;
        page = page < this.total ? page + 1 : 1;
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
    },
    // 加载
    progress(type) {
      this.loadedRatio = type;
      if (type == 1) {
        this.loading.close();
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.1)",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  .bigBlock {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #282b33;

    .w-1292 {
      width: 1292px !important;
    }

    .iframeWrap {
      position: relative;

      .faceImg {
        width: 646px;
        height: 937px;
        position: absolute;
        top: 0;
        height: 0;
      }

      width: 646px;
      height: 937px;
      background-color: white;
      margin: auto;
      position: relative;

      .pdfBox {
        display: flex;

        .pdf-item {
          width: 50%;
        }
      }

      .coverBox {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .tipsWrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .tipsBlock {
            width: 290px;
            height: 74px;
            background-color: rgba(0, 0, 0, 0.8);
            color: #ffffff;
            font-size: 18px;
            border-radius: 8px;
            text-align: center;
            line-height: 74px;
            margin-bottom: 32px;

            .icon-suotou {
              margin-right: 12px;
            }
          }

          .tipsBtn {
            width: 120px;
            height: 40px;
            color: #ffffff;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }

          .tipsBtn1 {
            background-color: #ff5e51;
          }

          .tipsBtn2 {
            background-color: #00ab8c;
          }

          .tipsBtn4 {
            background-color: #1061ff;
          }
        }
      }

      .rTag {
        position: absolute;
        left: -60px;
        width: 60px;
        height: 44px;
        border-radius: 24px 0 0 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;

        .symbol {
          position: absolute;
          left: 20px;
        }

        .tips {
          position: absolute;
          left: 45px;
        }
      }

      .lTag {
        position: absolute;
        top: 130px;
        right: -120px;
        width: 120px;
        height: 44px;
        border-radius: 0 24px 24px 0;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
      }

      .lTag_bg1 {
        background: #ff5d51;
      }

      .lTag_bg2 {
        background: #00ab8c;
      }

      .lTag_bg4 {
        background: #1061ff;
      }

      .top-200 {
        top: 200px;
      }

      .top-264 {
        top: 264px;
      }

      .top-328 {
        top: 328px;
      }

      .blue {
        background-color: #356fb2;
      }

      .blue:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .brown {
        background-color: #b27135;
      }

      .brown:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .green {
        background-color: #308e61;
      }

      .green:hover {
        width: 120px !important;
        left: -120px !important;
        transition: 300ms;
      }

      .fullScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 334px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
      }

      .dualScreen {
        width: 40px;
        height: 40px;
        border: 1px solid #666e7f;
        position: absolute;
        top: 373px;
        right: -80px;
        text-align: center;
        line-height: 40px;
        color: #666e7f;
        font-size: 16px;
        cursor: pointer;
      }

      .leftArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 533px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
        cursor: pointer;
      }

      .rightArrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 612px;
        right: -80px;
        color: #666e7f;
        text-align: center;
        line-height: 80px;
        border: 1px solid #666e7f;
        cursor: pointer;
      }

      .contentWrap {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .contentBox {
          width: 566px;
          height: 760px;
          background-color: #ffffff;
          padding: 20px;
          overflow: auto;
        }
      }
    }
  }
}
</style>
